<template>
  <b-container class="orders-container">
    <h1 v-if="!isRbAdmin">Orders</h1>
    <Alert v-if="alert.model" :variant="alert.variant" :icon="alert.variant === 'red' ? 'exclamation' : 'check'">
      {{ alert.text }}
    </Alert>
    <div class="flex items-center mb-4">
      <div class="flex flex-wrap">
        <GenericSearch :value="form.input" @updateSearch="updateSearch" class="mr-2" />
        <BaseButton v-b-toggle.advanced-search variant="secondary"> Advanced Search </BaseButton>
        <div class="flex xs:flex-wrap gap-2 xs:ml-0 xs:mt-4 lg:mt-0 lg:ml-2">
          <CreateCashSaleModal v-if="canCreateCashSales && !showCreateOrderV2" />
          <CreateOrderV2 v-if="showCreateOrderV2 && showCreateOrderV2" @triggerRefresh="triggerRefresh" />
          <BatchProcessButton v-if="showProcessBatchOrders" @triggerRefresh="triggerRefresh" />
        </div>
      </div>
    </div>
    <b-collapse id="advanced-search">
      <b-row class="container-search">
        <b-col>
          <OrderFilters
            @orderFilterChanged="onOrderFilterChanged"
            @orderFiltersReset="onOrderFiltersReset"
            :defaultStartDateTime="form.filter.startDateTime"
            :defaultEndDateTime="form.filter.endDateTime"
          />
        </b-col>
      </b-row>
    </b-collapse>
    <div class="overflow-x-hidden">
      <div class="col p-0">
        <div class="table-overflow">
          <b-table
            v-if="initalized"
            show-empty
            id="table"
            class="orders-table"
            striped
            sort-icon-left
            :fields="table.fields"
            :per-page="pagination.pageSize"
            :current-page="currentPage"
            :items="items"
            :sort-by.sync="pagination.sortBy"
            :sort-desc.sync="pagination.sortDesc"
            empty-text="test"
          >
            <template #empty>
              <div class="text-center">There are no records to show.</div>
              <div class="text-center">Try adjusting the search criteria in 'Advanced Search'.</div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template v-slot:cell(id)="row">
              <router-link :to="{ path: `/order/${row.item.id}` }">
                <p class="text-gray-900 underline text-left">{{ formatUuid(row.item.id) }}</p>
              </router-link>
            </template>
            <template v-slot:cell(fullName)="row"> {{ row.item.firstName }} {{ row.item.lastName }} </template>
            <template v-slot:cell(createdAt)="row">
              <span
                v-b-tooltip.hover
                :title="
                  formatDateTime(parseISO(row.item.createdAt), {
                    format: 'dateTime',
                    timeZone: sessionUserTimeZone,
                    displayTimeZone: true
                  })
                "
                class="dashline"
              >
                {{ formatDateTime(parseISO(row.item.createdAt)) }}
              </span>
            </template>
            <template v-if="showReceivedDate" v-slot:cell(receivedDate)="row">
              <span
                v-b-tooltip.hover
                :title="
                  row.item.receivedDate
                    ? formatDateTime(parseISO(row.item.receivedDate), {
                        format: 'dateTime',
                        timeZone: sessionUserTimeZone,
                        displayTimeZone: true
                      })
                    : 'No received date available'
                "
                :class="{ dashline: row.item.receivedDate }"
              >
                {{ row.item.receivedDate ? formatDateTime(parseISO(row.item.receivedDate)) : '-' }}
              </span>
            </template>
            <template v-slot:cell(edit)="row">
              <span v-b-modal="'edit-order-' + row.item.id"><i class="fa-regular fa-pen-to-square"></i></span>
              <EditOrderModalV2
                :modalId="row.item.id"
                :orderId="row.item.id"
                @orderUpdated="triggerRefresh"
                v-if="showEditOrderV2"
              />

              <EditOrderModal :modalId="row.item.id" :orderId="row.item.id" v-else />
            </template>
            <template v-slot:cell(show_details)="row">
              <b-button
                v-if="sessionUser.email === 'mnp-demo@rafflebox.ca'"
                @click="goToOrderDetails(row.item.id)"
                variant="outline-secondary"
              >
                Show Details
              </b-button>
              <b-button v-else @click="row.toggleDetails" variant="outline-secondary">
                {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
              </b-button>
            </template>
            <template v-slot:cell(status)="data">
              <OrderStatusBadge :status="data.value" />
            </template>
            <template v-slot:row-details="row">
              <ViewOrder
                :orderComment="row.item.info && row.item.info.comment ? row.item.info.comment : undefined"
                :orderId="row.item.id"
                :customerId="row.item.customerId ? row.item.customerId : undefined"
                @voidSuccess="showSuccess"
                @triggerRefresh="triggerRefresh"
              />
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <div colspan="7" class="text-right" v-if="pagination.total">
      Total Rows: <strong> {{ pagination.total }} </strong>
    </div>

    <Pagination
      v-if="pagination"
      :pages="pagination.totalPages"
      :currentPage="currentPage"
      :total="pagination.total"
      @updateCurrentPage="updateCurrentPage"
      class="mb-10"
    />
  </b-container>
</template>

<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import { subDays } from 'date-fns';
import OrderServiceV2 from '@/lib/order-service-v2';
import ViewOrder from '@/components/ViewOrder';
import CreateCashSaleModal from '@/components/modals/CreateCashSaleModal';
import CreateOrderV2 from '@/components/modals/CreateOrderV2';
import EditOrderModal from '@/components/EditOrderModal';
import EditOrderModalV2 from '@/components/EditOrderModalV2';
import Pagination from '@/components/ui/Pagination';
import OrderFilters from '@/components/OrderFilters';
import { isStripePurchaseTimedOut, setStripePurchaseTimedOutError } from '@/lib/stripe-purchase-timeout';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';
import BatchProcessButton from '@/components/BatchProcessButton';
import GenericSearch from '@/components/GenericSearch';
import Alert from '@/components/ui/Alert';
import BaseButton from '@/components/rbComponents/BaseButton.vue';
import OrderStatusBadge from '@/components/OrderStatusBadge.vue';

export default {
  name: 'Orders',
  components: {
    Alert,
    ViewOrder,
    CreateCashSaleModal,
    CreateOrderV2,
    OrderFilters,
    BatchProcessButton,
    GenericSearch,
    EditOrderModalV2,
    EditOrderModal,
    BaseButton,
    Pagination,
    OrderStatusBadge
  },
  props: ['isRbAdmin'],
  watch: {
    'form.input': function () {
      this.omitCount = false;
      if (this.form.input?.trim().length >= 3) {
        this.refreshTable();
      }
    },
    'pagination.sortBy': function () {
      this.omitCount = false;
      this.refreshTable();
    }
  },
  async created() {
    // Get sessionUser
    this.sessionUser = await getAuth().sessionUser();

    const batchId = this.$route.query.batchId;
    if (batchId) {
      this.form.filter.batchId = batchId;
      this.refreshTable();
    }
    // Route is used to determine if we should clear the form on load
    this.route = this.$route.path;
    if (this.route === '/admin') {
      this.$store.dispatch('setForm', {
        input: '',
        filter: {
          event: null,
          station: null,
          device: null,
          status: null,
          startDateTime: subDays(new Date(), 90),
          endDateTime: null
        }
      });
    }
    const search = this.$route.query.search;

    if (search) {
      this.form.input = search;
      this.routeQuery = true;
    }

    this.canCreateCashSales = await unleashFeatureEnabled(UnleashKeys.CreateCashSales);
    this.showProcessBatchOrders = await unleashFeatureEnabled(UnleashKeys.ProcessBatchOrders);
    this.showCreateOrderV2 = await unleashFeatureEnabled(UnleashKeys.CreateOrderV2);
    this.showEditOrderV2 = await unleashFeatureEnabled(UnleashKeys.EditOrderV2);
    this.showReceivedDate = await unleashFeatureEnabled(UnleashKeys.MNPReceivedDate);
    this.initalized = true;
  },
  // Clear the form on destroy if not on the regular orders page
  beforeDestroy() {
    if (this.route !== '/orders') {
      this.$store.dispatch('setForm', {
        input: '',
        filter: {
          event: null,
          station: null,
          device: null,
          status: null,
          startDateTime: null,
          endDateTime: null
        }
      });
    }
  },
  data() {
    return {
      route: null,
      alert: {
        text: '',
        variant: '',
        model: false
      },
      modalConfig: {
        showHeaderClose: true,
        cancelButtonLabel: 'Close',
        showCloseModalButton: false
      },
      showCreateCashSale: false,
      organizationCountry: null,
      table: {
        isBusy: false,
        fields: [
          {
            key: 'id',
            label: 'ID',
            sortable: false,
            formatter: (value) => {
              return this.formatUuid(value);
            }
          },
          {
            key: 'subscriptionId',
            label: 'Sub ID',
            sortable: false,
            formatter: (value) => {
              return this.formatUuid(value);
            }
          },
          {
            key: 'batchId',
            label: 'Batch ID',
            sortable: false
          },
          {
            key: 'createdAt',
            label: 'Date',
            sortable: true,
            formatter: (val) => this.formatDateTime(this.parseISO(val))
          },
          {
            key: `fullName`,
            label: 'Name',
            sortable: true
          },
          { key: 'email', label: 'Email', sortable: true },
          {
            key: 'phone',
            formatter: (val) => this.formatTelephone(val),
            tdClass: 'phoneColumn',
            label: 'Phone Number',
            sortable: true
          },
          { key: 'numTickets', label: 'Tickets', sortable: false },
          {
            key: 'amountPaidCents',
            label: 'Amount',
            sortable: true,
            formatter: (val) => this.formatCurrency(val / 100, { precision: 0 })
          },
          { key: 'status', label: 'Status', sortable: false },
          { key: 'edit' },
          'show_details'
        ]
      },
      routeQuery: false,
      toggleValue: false,
      canCreateCashSales: false,
      showProcessBatchOrders: false,
      showCreateOrderV2: false,
      showEditOrderV2: false,
      sessionUser: null,
      initalized: false,
      omitCount: true,
      pagination: {
        total: 0,
        totalPages: 0,
        pageSize: 10,
        sortBy: 'id',
        sortDir: 'desc'
      },
      currentPage: 0,
      showReceivedDate: false
    };
  },
  computed: {
    sortDir() {
      return this.table.sortDesc === true ? 'desc' : 'asc';
    },
    form() {
      return this.$store.getters.getForm;
    }
  },
  methods: {
    goToOrderDetails(orderId) {
      this.$router.push({ path: `/order/${orderId}` });
    },
    updateSearch(search) {
      this.form.input = search;
      this.refreshTable();
    },
    refreshTable() {
      this.$root.$emit('bv::refresh::table', 'table');
    },
    showSuccess(message) {
      this.refreshTable();
      this.alert.text = message;
      this.alert.model = true;
      this.alert.variant = 'green';
    },
    updateCurrentPage(page) {
      this.currentPage = page;
      this.$nextTick(async () => {
        await this.items();
      });

      this.refreshTable();
    },
    getTotalPages(pagination) {
      if (pagination && pagination.total) {
        return Math.ceil(parseInt(pagination.total, 10) / parseInt(pagination.pageSize, 10)) || 1;
      } else {
        return 0;
      }
    },
    async items() {
      try {
        if (this.routeQuery) {
          const result = await OrderServiceV2.retrieveOrder(this.form.input.trim());

          return result ? [result] : [];
        }

        const params = {
          organizationId: this.isRbAdmin ? undefined : this.$store.state.organization.id,
          search: this.form.input.trim().length > 0 ? this.form.input.trim() : undefined,
          eventId: this.$route.query.eventId || this.form.filter.event?.id || undefined,
          stationId: this.form.filter.station ? this.form.filter.station?.id : undefined,
          deviceId: this.form.filter.device ? this.form.filter.device?.id : undefined,
          status: this.$route.query.status || this.form.filter.status || undefined,
          from: this.form.filter.startDateTime || undefined,
          to: this.form.filter.endDateTime || undefined,
          batchId: this.form.filter.batchId || undefined,
          page: this.currentPage,
          pageSize: this.pagination.pageSize,
          sortDir: this.pagination.sortDir,
          sortBy: this.pagination.sortBy,
          omitCount: this.omitCount
        };

        if (this.$route.query.search) {
          delete params.from;
        }
        const result = await OrderServiceV2.listOrders(params);

        this.pagination = result.pagination;
        this.pagination.totalPages = this.getTotalPages(this.pagination);

        this.table.rows = result.pagination.total;
        result.data.forEach((order) => {
          // if pending and old show as error
          if (isStripePurchaseTimedOut(order)) {
            setStripePurchaseTimedOutError(order);
          }
        });

        const hasReceivedDate = result.data.some(
          (order) => order.receivedDate !== null && order.receivedDate !== undefined
        );

        // Add receivedDate if ff enabled and at least one order has the value
        if (
          this.showReceivedDate &&
          hasReceivedDate &&
          !this.table.fields.find((field) => field.key === 'receivedDate')
        ) {
          this.table.fields.splice(4, 0, {
            // Insert after createdAt
            key: 'receivedDate',
            label: 'Received Date'
          });
        }

        return result.data;
      } catch (error) {
        this.handleError(`Failed to load orders: ${error.message}`);
        return [];
      }
    },

    handleError(text) {
      this.alert.text = text;
      this.alert.model = true;
      this.alert.variant = 'red';
    },
    onOrderFilterChanged() {
      this.omitCount = false;
      this.refreshTable(false);
    },
    onOrderFiltersReset() {
      this.omitCount = true;
      this.refreshTable(true);
    },
    formatTelephone(val) {
      if (val !== null) {
        return this.formatPhone(val);
      }

      return '';
    },
    triggerRefresh(alert) {
      if (alert) {
        this.alert.text = alert.text;
        this.alert.model = alert.model;
        this.alert.variant = alert.variant;
      }
      this.refreshTable();
    }
  }
};
</script>

<style scoped>
.orders-container {
  text-align: left;
  max-width: 90%;
}

.container-search {
  padding-bottom: 1rem;
}

.container-void {
  padding-top: 1rem;
}

.dashline {
  border-bottom: 1px dashed;
}

.link-color {
  color: #2c3e50;
}
</style>
