<template>
  <GenericModal title="Update Player" size="md">
    <template #openButton="{ openModal }">
      <button
        @click="openModal"
        class="p-2 focus:ring focus:ring-green-200 rounded-lg hover:opacity-80"
        id="editPlayerButton"
      >
        <font-awesome-icon class="text-xl" :icon="['far', 'pen-to-square']" />
      </button>
    </template>
    <template>
      <div>
        <ErrorDisplay v-if="errorMessage" :error="errorMessage" />

        <TextInput
          ref="firstName"
          label="First Name"
          name="first-name"
          :required="true"
          v-model="playerInformation.firstName"
          class="flex-1 relative"
        />
        <TextInput
          ref="lastName"
          label="Last Name"
          name="last-name"
          :required="true"
          min="2"
          v-model="playerInformation.lastName"
          class="flex-1"
        />
        <EmailInput
          ref="email"
          label="Email"
          name="email"
          v-model="playerInformation.email"
          class="flex-1"
          :required="true"
        />
        <TextInput
          ref="phone"
          label="Phone"
          name="phone"
          :required="true"
          :numeric="true"
          max="10"
          min="10"
          v-model="playerInformation.phone"
          class="flex-1"
        />
        <TextInput
          ref="address"
          label="Address"
          name="address"
          v-model="playerInformation.address"
          class="flex-1"
          :required="true"
        />
        <CountrySelect v-if="country" v-model="country" required />
        <TextInput
          ref="city"
          label="City"
          name="city"
          v-model="playerInformation.city"
          class="flex-1"
          :required="true"
        />
        <ProvinceDropdownInput
          v-if="country"
          ref="province"
          name="province"
          label="Province / State"
          v-model="playerInformation.province"
          :required="true"
          :country="country"
          class="flex-1"
        />
        <PostalInput
          v-if="country === 'CA'"
          ref="postal"
          label="Postal Code"
          name="postal-code"
          v-model="playerInformation.postal"
          :province="playerInformation.province"
          :required="true"
          class="flex-1"
        />
        <ZipInput
          v-if="country === 'US'"
          ref="postal"
          label="ZIP Code"
          name="postal-code-billing"
          :required="true"
          v-model="playerInformation.postal"
          :province="playerInformation.province"
          class="flex-1"
        />
      </div>
    </template>
    <template slot="footer" slot-scope="{ closeModal }">
      <BaseButton variant="secondary" @click.native="close(closeModal)">Cancel</BaseButton>
      <BaseButton
        variant="success"
        :loading="submitting"
        @click.native="submit(closeModal)"
        class="ml-2"
        id="submitButton"
        >Save</BaseButton
      >
    </template>
  </GenericModal>
</template>
<script>
import GenericModal from '@/components/modals/GenericModal';
import BaseButton from '@/components/rbComponents/BaseButton.vue';
import TextInput from '@/components/inputs/TextInput';
import EmailInput from '@/components/inputs/EmailInput';
import PostalInput from '@/components/inputs/PostalInput';
import ZipInput from '@/components/inputs/ZipInput';
import CountrySelect from '@/components/CountrySelect';
import ProvinceStateService from '@/lib/province-state-view-service';
import ProvinceDropdownInput from '@/components/inputs/ProvinceDropdownInput';
import ErrorDisplay from '@/components/ErrorDisplay';

import PlayerServiceV2 from '@/lib/player-service-v2';

export default {
  components: {
    TextInput,
    EmailInput,
    ProvinceDropdownInput,
    PostalInput,
    ZipInput,
    GenericModal,
    CountrySelect,
    BaseButton,
    ErrorDisplay
  },
  props: {
    player: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      submitting: false,
      errorMessage: null,
      playerInformation: {
        firstName: null,
        lastName: null,
        title: null,
        age: null,
        address: null,
        city: null,
        province: null,
        postal: null,
        email: null,
        phone: null
      },
      country: null,
      provinceStateView: {},
      provinceStateOptions: {}
    };
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  async created() {
    this.country = this.player.country;
    this.provinceStateView = ProvinceStateService.getDefaultView(this.country);
    this.provinceStateOptions = this.provinceStateView.dropdownOptions;
  },
  watch: {
    editPlayerInformation: {
      handler() {
        if (this.editPlayerInformation !== this.playerInformation) {
          this.playerInformation = { ...this.editPlayerInformation };
        }
      },
      deep: true
    }
  },
  mounted() {
    this.playerInformation = { ...this.player };
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    customValidation(silent = false) {
      const firstName = this.$refs.firstName.isValid(silent);
      const lastName = this.$refs.lastName.isValid(silent);
      const phone = this.$refs.phone.isValid(silent);
      const province = this.$refs.province.isValid(silent);

      return firstName && lastName && province && phone;
    },
    close(closeModal) {
      this.playerInformation = { ...this.player };
      this.errorMessage = null;
      closeModal();
    },
    async submit(closeModal) {
      if (this.customValidation()) {
        try {
          this.submitting = true;
          const player = { ...this.playerInformation };
          player.country = this.country;
          player.provinceCode = this.country === 'CA' ? this.playerInformation.province : null;
          player.postal = this.country === 'CA' ? this.playerInformation.postal.replace(/\s+/g, '') : null;
          player.zip = this.country === 'US' ? this.playerInformation.postal : null;
          player.id = this.player.id;

          await PlayerServiceV2.updatePlayer(this.player.id, player);
          this.submitting = false;
          this.$emit('playerUpdated');
          closeModal();
        } catch (error) {
          this.errorMessage = 'Unable to update player';
          this.submitting = false;
          return;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.custom-control {
  padding-left: 2rem;
}

.custom-checkbox {
  .custom-control-label::before {
    top: 0;
    border: 1px solid #d5d7d7;
    border-radius: 0.3rem;
    height: 1.4rem;
    width: 1.4rem;
    left: -2rem;
  }

  .custom-control-label::after {
    top: 0;
    border: 1px solid #d5d7d7;
    border-radius: 0.3rem;
    height: 1.4rem;
    width: 1.4rem;
    left: -2rem;
  }

  .custom-control-input:checked {
    & ~ .custom-control-label::before {
      background-color: #5db966;
    }

    & ~ .custom-control-label::after {
      background-color: #5db966;
    }
  }

  .custom-control-input:disabled:checked {
    & ~ .custom-control-label::before {
      background-color: #9f9f9f;
      border-color: #c1c1c1;
    }

    & ~ .custom-control-label::after {
      background-color: #9f9f9f;
      border-color: #c1c1c1;
    }
  }
}

.disabled {
  color: #6c757d;
}
</style>
