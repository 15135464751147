<template>
  <b-form-group label="Roles">
    <b-form-checkbox-group
      id="roles-group"
      v-model="currentRoles"
      v-validate="{ required: true }"
      :state="validateState('input-roles')"
      name="input-roles"
      data-vv-as="roles"
      :disabled="disabled"
    >
      <b-row class="role-checkboxes">
        <b-col>
          <b-form-checkbox class="d-block" value="Account Owner" v-if="isRbAdmin">Account Owner</b-form-checkbox>
          <b-form-checkbox class="d-block mt-5" value="Developer" v-if="canAssignDeveloperRole"
            >Developer</b-form-checkbox
          >
          <b-form-checkbox class="d-block mt-5" value="Raffle Admin">Raffle Manager</b-form-checkbox>
          <b-form-checkbox class="d-block mt-5" value="Goldrush Admin">Goldrush Admin</b-form-checkbox>
          <b-form-checkbox class="d-block mt-5" value="Goldrush Ticket Seller">Goldrush Ticket Seller</b-form-checkbox>
          <b-form-checkbox class="d-block mt-5" value="Ticket Seller">Ticket Seller</b-form-checkbox>
          <b-form-checkbox class="d-block mt-5" value="Invoice Validator" v-if="isRbAdmin">
            Invoice Validator
          </b-form-checkbox>
          <b-form-checkbox class="d-block mt-5" value="Invoice Authorizer" v-if="isRbAdmin">
            Invoice Authorizer
          </b-form-checkbox>
        </b-col>
        <b-col>
          <div v-if="isRbAdmin && isRbUser && canAssignRbRoles">
            <div>
              <p class="italic mb-1 font-bold" :class="{ disabled: disabled }">For Internal Rafflebox Use Only</p>
              <hr />
            </div>
            <b-form-checkbox class="d-block mt-5" value="RB Admin">Rafflebox Admin</b-form-checkbox>
            <b-form-checkbox class="d-block mt-5" value="Rafflebox Finance">Rafflebox Finance</b-form-checkbox>
          </div>
          <div v-if="canAssignMNPRoles">
            <div class="mt-4">
              <p class="italic mb-1 font-bold" :class="{ disabled: disabled }">Home Lottery Roles</p>
              <hr />
            </div>
            <b-form-checkbox class="d-block mt-5" value="Agent Manager">Agent Manager</b-form-checkbox>
            <b-form-checkbox class="d-block mt-5" value="Contact Center Agent">Contact Center Agent</b-form-checkbox>
            <b-form-checkbox class="d-block mt-5" value="In Person Sales">In Person Sales</b-form-checkbox>
            <b-form-checkbox class="d-block mt-5" value="Banking Team">Banking Team</b-form-checkbox>
            <b-form-checkbox class="d-block mt-5" value="Data Entry Team">Data Entry Team</b-form-checkbox>
            <b-form-checkbox class="d-block mt-5" value="Customer Service">Customer Service</b-form-checkbox>
            <b-form-checkbox class="d-block mt-5" value="Data Processing">Data Processing</b-form-checkbox>
          </div>
        </b-col>
      </b-row>
    </b-form-checkbox-group>
  </b-form-group>
</template>

<script>
import { getAuth, Roles } from '@rafflebox-technologies-inc/auth-service-sdk';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';

const name = 'input-roles';

export default {
  props: {
    roles: {
      type: Array
    },
    email: {
      type: String
    },
    disabled: {
      type: Boolean
    }
  },
  data() {
    return {
      currentRoles: this.roles,
      isRbAdmin: false,
      isAccountOwner: false,
      canAssignMNPRoles: false,
      canAssignRbRoles: false,
      canAssignDeveloperRole: false
    };
  },
  watch: {
    currentRoles() {
      this.$emit('onRoleChange', this.currentRoles);
    }
  },
  computed: {
    isRbUser() {
      return (
        this.email?.endsWith('@rafflebox.ca') ||
        this.email?.endsWith('@rafflebox.us') ||
        this.email?.endsWith('@rafflebox.org')
      );
    }
  },
  async created() {
    const sessionUser = await getAuth().sessionUser();
    // Render of `<template />` is sync. Therefore we save the role in the component’s data, then render that data.
    this.isRbAdmin = sessionUser.hasRole(Roles.RB_ADMIN);
  },
  async mounted() {
    this.canAssignMNPRoles = await unleashFeatureEnabled(UnleashKeys.EditUserForMNPRoles);
    this.canAssignRbRoles = await unleashFeatureEnabled(UnleashKeys.CanAssignRbRoles);
    this.canAssignDeveloperRole = await unleashFeatureEnabled(UnleashKeys.CanAssignDeveloperRole);
  },
  methods: {
    validateState() {
      if (this.veeFields[name] && (this.veeFields[name].dirty || this.veeFields[name].validated)) {
        return !this.veeErrors.has(name);
      }

      return null;
    },
    isValid() {
      this.$validator.validate();
      return this.veeFields[name]['invalid'] === false;
    }
  }
};
</script>

<style lang="scss">
.custom-control {
  padding-left: 2.2rem;
}

.custom-checkbox {
  .custom-control-label::before {
    top: 0;
    border: 1px solid #d5d7d7;
    border-radius: 0.3rem;
    height: 1.4rem;
    width: 1.4rem;
    left: -2.2rem;
  }

  .custom-control-label::after {
    top: 0;
    border: 1px solid #d5d7d7;
    border-radius: 0.3rem;
    height: 1.4rem;
    width: 1.4rem;
    left: -2.2rem;
  }

  .custom-control-input:checked {
    & ~ .custom-control-label::before {
      background-color: #5db966;
    }

    & ~ .custom-control-label::after {
      background-color: #5db966;
    }
  }

  .custom-control-input:disabled:checked {
    & ~ .custom-control-label::before {
      background-color: #9f9f9f;
      border-color: #c1c1c1;
    }

    & ~ .custom-control-label::after {
      background-color: #9f9f9f;
      border-color: #c1c1c1;
    }
  }
}

.disabled {
  color: #6c757d;
}
</style>
