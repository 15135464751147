<template>
  <div>
    <b-alert v-if="error" variant="danger" show dismissible>{{ error }}</b-alert>
    <b-alert v-if="success" variant="success" show dismissible>{{ success }}</b-alert>
    <b-spinner v-if="loading" />
    <b-card-group deck v-else style="background: #ffffff">
      <b-card style="max-width: 15rem; border: none">
        <h5 class="order-info">
          <strong :id="`download-tickets-button-${order.id}`">Order #{{ formatUuid(orderId) }}</strong>
          <b-popover :target="`download-tickets-button-${order.id}`" triggers="hover click blur" placement="bottom">
            <template #title>Order ID</template>
            {{ orderId }}
          </b-popover>
          <sup
            :id="`no-void-${order.id}`"
            v-if="canViewRefundModalV2 ? !voidableOrderV2 : !voidableOrder"
            class="no-void"
          >
            <i class="fa-regular fa-circle-exclamation text-danger" v-if="showExclamationIcon"></i>
            <b-tooltip :target="`no-void-${order.id}`" triggers="hover">
              <span v-if="order.voidOrder">{{ formatMessage(order.voidOrder.reason) }} <br /></span>
            </b-tooltip>
          </sup>
          <div class="event-name-text">
            <a :href="`/raffle?id=${order.eventId}`">{{ order.eventName }}</a>
          </div>
          <div class="event-name-text flex flex-row align-items-center gap-2" v-if="order.deviceName">
            <font-awesome-icon :icon="['fas', 'tablet-screen-button']" />
            <p>{{ order.deviceName }}</p>
          </div>
          <div class="event-name-text flex flex-row align-items-center gap-2" v-if="order.sellerName">
            <font-awesome-icon :icon="['far', 'id-badge']" />
            <p>{{ order.sellerName }}</p>
          </div>
        </h5>
        <p v-if="order.drawNum">
          <strong>Draw #{{ order.drawNum }}</strong>
        </p>
        <p v-if="order.deviceUuid">
          <strong>Device #{{ formatUuid(order.deviceUuid) }}</strong>
        </p>

        <div class="order-info">{{ formatDateTime(parseISO(order.createdAt)) }}</div>

        <b-row>
          <b-col>
            <b-badge variant="success" class="order-info">
              {{ formatOrderType(order.type) }}
            </b-badge>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-badge href="#" v-if="merchant" variant="primary" class="order-info" :id="`merchant-badge-${order.id}`">
              {{ merchant }}
            </b-badge>
          </b-col>
        </b-row>
        <b-popover :target="`merchant-badge-${order.id}`" triggers="hover click blur" placement="bottom">
          <template #title>Reference ID</template>
          {{ order.referenceId }}
        </b-popover>

        <div class="container-void order-info">
          <b-alert v-if="order.errorCode" show variant="danger"
            >{{ order.errorCode }} - {{ order.errorMessage }}</b-alert
          >
          <b-alert v-if="order.refund" variant="warning" show>{{
            order.voidedAt ? 'Order was voided on ' + formatDateTime(parseISO(order.voidedAt)) : 'Order was voided'
          }}</b-alert>
          <div v-else>
            <div class="flex flex-row" v-if="voidableOrderV2 && canViewRefundModalV2">
              <RefundModalV2
                :order="order"
                :groupOrders="groupOrders"
                @success="voidSuccess"
                :disabled="!order.voidOrder.voidableV2"
              />
              <span v-b-tooltip.hover.top="`${nonVoidableReason}`" v-if="!order.voidOrder.voidableV2">
                <i class="pl-2 fa-solid fa-circle-info"></i>
              </span>
            </div>
            <b-button
              v-b-modal="'void-order-' + order.id"
              v-if="!canViewRefundModalV2 && voidableOrder && viewRefundModal"
              :disabled="order.refund"
              variant="outline-dark"
              auto
            >
              Void Order
            </b-button>

            <RefundModal v-if="!canViewRefundModalV2" :modalId="order.id" :order="order" show />
          </div>
        </div>
        <b-row v-if="createdBy.name">
          <b-col>
            <span>Created By: {{ createdBy.name }}</span>
          </b-col>
        </b-row>
        <b-row v-if="createdBy.email">
          <b-col>
            <span class="break-all">{{ createdBy.email }}</span>
          </b-col>
        </b-row>
      </b-card>
      <b-card style="max-width: 20rem; border: none">
        <h4>Order Comments</h4>
        <p v-if="orderComment">{{ orderComment }}</p>
      </b-card>
      <b-card style="max-width: 15rem; border: none">
        <h4>Customer</h4>
        <CustomerInfo :customer="order" />
        <p v-if="order.ipAddress">IP Address: {{ order.ipAddress }}</p>
      </b-card>
      <b-card v-if="order.secondaryName" style="max-width: 15rem; border: none">
        <h4 v-if="order.secondaryName">Additional Names:</h4>
        <p v-if="order.secondaryName">{{ order.secondaryName }}</p>
      </b-card>
      <b-card style="max-width: 20rem; border: none">
        <b-list-group>
          <b-list-group-item class="d-flex justify-content-between align-items-center">
            Currency
            <b-badge variant="light" pill>{{ order.currency.toUpperCase() }}</b-badge>
          </b-list-group-item>
          <b-list-group-item class="d-flex justify-content-between align-items-center" v-if="showTickets(order)">
            Tickets
            <b-badge variant="light" pill>{{ formatNumber(order.numTickets) }}</b-badge>
          </b-list-group-item>
          <b-list-group-item class="d-flex justify-content-between align-items-center">
            Total Amount
            <b-badge variant="light" pill>{{ formatCurrency(order.amountPaidCents / 100) }}</b-badge>
          </b-list-group-item>
          <b-list-group-item v-if="order.subscriptionId" class="d-flex justify-content-between align-items-center">
            Subscription Amount
            <b-badge v-if="subscriptionAmount" variant="light" pill>{{ formatCurrency(subscriptionAmount) }}</b-badge>
          </b-list-group-item>
        </b-list-group>
      </b-card>
      <b-card style="max-width: 20rem; border: none">
        <b-link v-if="customerId" :href="'/customer/?id=' + customerId" class="btn rb-link">
          <i class="fa-solid fa-user rb-link-icon"></i>View Customer
        </b-link>
        <b-link :href="'/tickets/?orderId=' + orderId" class="btn rb-link" v-if="canViewTickets && showTickets(order)">
          <i class="fa-solid fa-ticket rb-link-icon"></i>View Tickets</b-link
        >
        <b-link
          :href="`${raffleboxUrl}/order/${orderId}`"
          class="btn rb-link"
          v-if="canViewTickets && showTickets(order)"
        >
          <i class="fa-solid fa-ticket rb-link-icon"></i>Preview Tickets
        </b-link>
        <b-link
          :disabled="buttonDisabled"
          @click="getDownloadTicketsUrl"
          class="btn rb-link"
          v-if="canDownloadTickets && order.status === 'ACTIVE' && showTickets(order)"
        >
          <b-spinner class="mx-2" small v-if="buttonDisabled"></b-spinner>
          <i class="fa-solid fa-download rb-link-icon"></i>Download Tickets</b-link
        >
        <b-alert v-if="errorMessage" show variant="danger" style="padding: 0 1rem">{{ errorMessage }}</b-alert>
        <b-link class="btn rb-link" v-b-modal="`email-order-${order.id}`" v-if="canEmailOrder && showTickets(order)">
          <i class="fa-solid fa-envelope rb-link-icon"></i>Email Order</b-link
        >
        <b-link
          class="btn rb-link"
          :href="`/tickets/print-mail?orderId=${order.id}`"
          v-if="canPrintOrder && showTickets(order)"
        >
          <i class="fa-solid fa-print rb-link-icon"></i>
          Print Order
        </b-link>
        <EmailOrderModal :modalId="order.id" :order="order" />
        <br />
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import { getAuth, Roles } from '@rafflebox-technologies-inc/auth-service-sdk';

import config from '@/config';
import OrderServiceV2 from '@/lib/order-service-v2';
import CustomerInfo from '@/components/CustomerInfo';
import RefundModal from '@/components/RefundModal';
import RefundModalV2 from '@/components/RefundModalV2';
import EmailOrderModal from '@/components/EmailOrderModal';
import formatOrderType, { isGoldrushPOSOrder } from '@/lib/format-order-type';
import { isStripePurchaseTimedOut, setStripePurchaseTimedOutError } from '@/lib/stripe-purchase-timeout';
import TicketServiceV2 from '@/lib/ticket-service-v2';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';

export default {
  props: ['orderComment', 'orderId', 'customerId'],
  components: { RefundModal, RefundModalV2, CustomerInfo, EmailOrderModal },
  data() {
    return {
      raffleboxUrl: config.RAFFLEBOX_URL,
      order: {},
      event: {},
      createdBy: {},
      subscriptionAmount: null,
      voidableOrder: false,
      voidableOrderV2: false,
      loading: true,
      error: false,
      success: false,
      buttonDisabled: false,
      fullPdfUrl: null,
      errorMessage: null,
      groupOrders: [],
      cartItems: [],
      canDownloadTickets: false,
      canViewRefundModalV2: false,
      viewRefundModal: false,
      canEmailOrder: false,
      canPrintOrder: false,
      canViewTickets: false
    };
  },
  async mounted() {
    this.canDownloadTickets = await unleashFeatureEnabled(UnleashKeys.DownloadTickets);
    this.canViewRefundModalV2 = await unleashFeatureEnabled(UnleashKeys.VoidOrdersV2);
    this.godMode = await unleashFeatureEnabled(UnleashKeys.GodMode);
    this.canEmailOrder = await unleashFeatureEnabled(UnleashKeys.EmailOrder);
    this.canPrintOrder = await unleashFeatureEnabled(UnleashKeys.PrintOrder);
    this.canViewTickets = await unleashFeatureEnabled(UnleashKeys.ViewTickets);
    this.viewRefundModal = !this.canViewRefundModalV2 || this.godMode;
    this.loading = true;

    try {
      const response = await OrderServiceV2.retrieveOrder(this.orderId);

      const order = response;
      if (isStripePurchaseTimedOut(order)) {
        setStripePurchaseTimedOutError(order);
      }

      this.order = order;
      if (this.order.orderGroupId) {
        await this.getOrderGroup();
      }

      this.loading = false;

      this.subscriptionAmount = this.order.subscriptionId
        ? await this.getSubscriptionAmount(this.order.subscriptionId)
        : null;
      const sessionUser = await getAuth().sessionUser();
      // Allow voidable as long as voidable equals true, and the person has the valid role.
      this.voidableOrder =
        this.order.voidOrder?.voidable === true &&
        sessionUser.hasRole([Roles.RB_ADMIN, Roles.RAFFLE_ADMIN, Roles.GOLDRUSH_ADMIN, Roles.ACCOUNT_OWNER]);

      this.voidableOrderV2 =
        this.order.voidOrder?.voidableV2 === true &&
        sessionUser.hasRole([Roles.RB_ADMIN, Roles.RAFFLE_ADMIN, Roles.GOLDRUSH_ADMIN, Roles.ACCOUNT_OWNER]);
    } catch (error) {
      this.error = this.parseError(error).message;
      this.loading = false;
    }
  },

  computed: {
    merchant: function () {
      switch (this.order?.merchant) {
        case 'stripe_connect':
          return 'Stripe Connect';
        case 'bambora':
          return 'Bambora';
        case 'stripe':
          return 'Stripe';
        case 'payfacto':
          return 'Payfacto';
        case 'global_payments':
          return 'Global Payments';
        default:
          return null;
      }
    },
    nonVoidableReason() {
      switch (this.order.voidOrder?.reason) {
        case 'order-has-processing-fee':
          return 'Order has processing fee, cannot void order';
        case 'order-already-refunded':
          return 'Order already refunded';
        case 'winner-status-already-set':
          return 'Winner already selected, cannot void order';
        default:
          return 'Non-voidable order';
      }
    },
    showExclamationIcon() {
      return this.order.voidOrder?.reason || this.voidableOrder || this.voidableOrderV2;
    }
  },

  methods: {
    showTickets(order) {
      return order.type !== 'CUSTOM_ONLINE_PAYMENT_CREDIT';
    },
    voidSuccess(message) {
      this.$emit('voidSuccess', message);
    },
    async getDownloadTicketsUrl() {
      this.buttonDisabled = true;
      try {
        const params = {
          orderId: this.order.id
        };
        const response = await TicketServiceV2.getDownloadTicketsUrl(params);
        this.fullPdfUrl = response.url;
        this.buttonDisabled = false;
        window.open(response.url, '_blank');
      } catch (error) {
        this.buttonDisabled = false;
        this.errorMessage = this.parseError(error).message;
      }
    },
    formatOrderType: (val) => {
      return formatOrderType(val);
    },
    formatMessage: (message) => {
      let newMsg = message;
      if (typeof message === 'string') {
        newMsg = newMsg.replace(/-/g, ' ');
        newMsg = newMsg.charAt(0).toUpperCase() + newMsg.slice(1);
      }

      return newMsg;
    },
    isGoldrushPOSOrder(value) {
      return isGoldrushPOSOrder(value);
    },
    async getSubscriptionAmount(subscriptionId) {
      try {
        return await OrderServiceV2.getSubscriptionAmount(subscriptionId);
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
    },
    async getOrderGroup() {
      const result = await OrderServiceV2.getOrderGroup(this.order.orderGroupId);

      if (result.createdBy) {
        this.createdBy = result.createdBy;
        this.createdBy.name = result.createdBy.name
          .split(' ')
          .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
          .join(' ');
      }

      if (result.cartItems) {
        this.groupOrders = result.cartItems.map((order) => {
          return {
            ...order,
            id: order.uuid
          };
        });
        for (const item of result.cartItems) {
          if (item.amountCents) {
            // Capture donation
            this.donationAmount = item.amountCents / 100;
          } else {
            const { ticketSeries } = item.ticketPricing;
            const ticketPackages = ticketSeries.map((ticketPackage) => {
              return {
                quantity: ticketPackage.groups,
                numTickets: ticketPackage.numTickets,
                price: ticketPackage.price
              };
            });

            const cartItem = {
              event: item.event,
              ticketPackages: ticketPackages
            };
            this.cartItems.push(cartItem);
          }
        }
      }
    },
    triggerRefresh() {
      this.$emit('triggerRefresh');
    }
  }
};
</script>
<style scoped>
.card-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.download-input {
  cursor: pointer;
}
.event-name-text {
  padding-top: 0.5rem;
  font-size: 16px;
}
.order-info {
  margin-top: 0.5rem;
}
.rb-link {
  padding: 0.25rem;
  border: 0px none;
  color: #000000;
}
.rb-link-icon {
  min-width: 2.5rem;
  color: #000000;
}
.no-void svg {
  fill: #dc3545;
  margin-left: 0.5rem;
  cursor: pointer;
}
</style>
<style>
.tooltip,
.tooltip-inner {
  max-width: 300px !important;
}
</style>
