/**
 *
 * @param {*} cardBrand - A string representing the card brand
 * @returns - A string that the payment endpoint expects
 */

const getCardType = (cardBrand) => {
  switch (cardBrand) {
    case 'VISA':
      return 'visa';
    case 'MASTERCARD':
      return 'mastercard';
    case 'AMERICAN_EXPRESS':
      return 'amex';
    case 'DISCOVER':
      return 'discover';
    case 'DINERS':
      return 'diners';
    case 'JCB':
      return 'jcb';
    case 'UNIONPAY':
      return 'unionpay';
    default:
      return 'unknown';
  }
};

export default getCardType;
